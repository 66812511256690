import React from 'react';
import "../css/ErrorMessage.css";

const ErrorMessage = (props) => {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-10">
        <div className="alert alert-danger" role="alert">
          {props.message}
        </div>
      </div>
    </div>
  );
}

export default ErrorMessage;
